require('./bootstrap');


/**
 * UI components
 */
//require('./notifications');
//require('./dialogs');
//require('./tooltips');
require('./select2');
//require('./datepicker');
