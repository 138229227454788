require('select2');

$.fn.select2.amd.define('select2/i18n/pt', [], require("select2/src/js/select2/i18n/pt"));

$.fn.select2.defaults.set('theme', 'bootstrap4');
$.fn.select2.defaults.set('minimumResultsForSearch', 10);
$.fn.select2.defaults.set('width', '100%');
$.fn.select2.defaults.set('language', 'pt');

const selector = 'select.form-control-select2';


$(document).ready(function () {
    $(selector).select2()
               .each(function () {
                   // Respeita indicação dos erros de validação
                   if ($(this).hasClass('is-invalid')) {
                       $(this).parent().find('.select2').addClass('is-invalid');
                   }

                   // Limpa quando é feito um form reset
                   $(this).closest('form').on('reset', () => {
                       $(this).select2('val', 0);
                   });
               });
});
